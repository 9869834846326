import axios from "./index";

//route to create a new project
export async function create(data) {
  return axios
    .post("/project/new", data)
    .then((res) => res.data)
    .catch((err) => err);
}

//route to create a new project for multiOrigin
export async function newProjectMultiOrigin(data) {
  return axios.post("/project/new/multiOrigin", data).then((res) => res.data);
}

//route to fetch all projects
export async function fetch(params) {
  return axios.post("project", params).then((res) => res.data);
}

//route to set scope of a project
export async function scope(formData) {
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  return axios
    .post("project/scope", formData, config)
    .then((res) => res.data)
    .catch((err) => {
      return { success: false };
    });
}

//fetch project details using project id
export async function fetchProjectDetails(projectId) {
  return axios
    .post("/project/details", { projectId: projectId })
    .then((res) => res.data);
}

//fetch url list using project id
export async function fetchUrlList(params) {
  const { projectId, pageNumber, pageSize } = params;
  return axios
    .get(`/scope/${projectId}/${pageNumber}/${pageSize}`)
    .then((res) => res.data);
}

//fetch url list using project id
// make 10 api calls to get each part of scope list according to partPart supplied
// Need to refactor the scope fetch part as soon as possible
export async function fetchAllUrlList(projectId) {
  // divide it in 10 different api calls
  const promises = new Array(10).fill().map((_, i) => {
    return axios
      .get(`/scope/allUrl/${projectId}?pagePart=${i}`)
      .then((res) => res.data.data.allUrlList)
      .catch((err) => []);
  });
  
  const urlsArr = await Promise.all(promises);
  const urlSet = new Set();
  const urls = [];
  urlsArr.flat().forEach(urlObj=>{
    if (!urlSet.has(urlObj.url)) {
      urls.push(urlObj);
      urlSet.add(urlObj.url);
    }
  });
    
  return {data: { allUrlList:urls},success:true};
  // return axios.get(`/scope/allUrl/${projectId}`).then((res) => res.data);
}

// delete project item
export async function deleteProject(params) {
  const { keepTm, projectId } = params;
  return axios
    .post(`/project/delete`, {
      keepTm,
      projectId,
    })
    .then((res) => res.data);
}

// update project item
export async function updateProject(params) {
  return axios
    .post(`/project/update`, params)
    .then((res) => res.data)
    .catch((err) => {
      return { success: false };
    });
}

// get word count report from backend for project report
export async function fetchProjectReport(params) {
  const { projectId, url, targetLang, translationType } = params;
  return axios
    .post(`/translation/status/wordcount`, {
      projectId,
      url,
      targetLang,
      translationType,
    })
    .then((res) => res.data);
}
//fetch project stats
export async function fetchProjectStats(projectId) {
  return axios.post("/project/stats", { projectId }).then((res) => res.data);
}

//fetch project stats urlwise
export async function fetchProjectStatsUrlWise(projectId, urls) {
  return axios
    .post("/project/stats/urlWise", { projectId, urls })
    .then((res) => res.data);
}

//set font size
export async function setFontSize(params) {
  return axios.post("/custom-font-size/add", params).then((res) => res.data);
}

//set no translation
export async function setNoTranslation(params) {
  return axios.post("no-translation-node/add", params).then((res) => res.data);
}

//setSwalekhIntegrationData
export async function setSwalekhIntegrationData(params) {
  return axios.post("swalekhIntegration/add", params).then((res) => res.data);
}

//create ignore pattern
export async function setIgnorePattern(params) {
  return axios.post("/ignore-pattern/add", params).then((res) => res.data);
}

//fetch font size list
export async function fetchFontSizeList(params) {
  return axios.post("/custom-font-size/get", params).then((res) => res.data);
}

//fetch ignore pattern list
export async function fetchIgnorePatternList(params) {
  return axios
    .post("/ignore-pattern/fetchIgnorePatterns", params)
    .then((res) => res.data);
}

//fetch no translation list
export async function fetchNoTranslationList(params) {
  return axios.post("/no-translation-node/get", params).then((res) => res.data);
}

//fetch no translation list
export async function fetchSwalekhIntegrationist(params) {
  return axios.post("/swalekhIntegration/get", params).then((res) => res.data);
}

//delete custom font element
export async function deleteCustomFontElement(params) {
  return axios.post("/custom-font-size/delete", params).then((res) => res.data);
}

//delete custom font element
export async function deleteNoTranslationElement(params) {
  return axios
    .post("/no-translation-node/delete", params)
    .then((res) => res.data);
}

//delete Swalekh Integration Element
export async function deleteSwalekhIntegrationElement(params) {
  return axios
    .post("/swalekhIntegration/delete", params)
    .then((res) => res.data);
}

//delete ignore pattern element
export async function deleteIgnorePatternElement(params) {
  return axios
    .post("/ignore-pattern/deleteIgnorePatterns", params)
    .then((res) => res.data);
}

// change cacheConfig status of a key
export async function toggleCacheConfigStatus(key, status, projectId) {
  return axios
    .post("/project/cache/update", { key, status, projectId })
    .then((res) => res.data)
    .catch((err) => {
      return { success: false };
    });
}

// change meta Tags status of a key
export async function toggleMetaTagsStatus(key, status, projectId) {
  return axios
    .post("/project/meta/update", { key, status, projectId })
    .then((res) => res.data)
    .catch((err) => {
      return { success: false };
    });
}

// post transliteration data
export async function setTransliterationData(formData) {
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  return axios
    .post("transliteration/post", formData, config)
    .then((res) => res.data);
}

// fetch transliteration data
export async function deleteTransliteration(params) {
  return axios.post("transliteration/delete", params).then((res) => res.data);
}

// fetch transliteration data
export async function getTransliterationData(params) {
  return axios.post("transliteration/get", params).then((res) => res.data);
}

//fetch Project id for free org admin
export async function fetchProjectId(params) {
  return axios.post("project/fetchProjectId", params).then((res) => res.data);
}
// clear cache call
export async function clearCacheCall() {
  return axios
    .get("snippet/cache/clear")
    .then((res) => res.data)
    .catch((err) => {
      return { success: false };
    });
}

export async function getLastProcessedUrl(body) {
  const { projectId } = body;
  return axios
    .post(`scope/crawl/last`, { projectId })
    .then((res) => res.data)
    .catch((err) => {
      return { success: false };
    });
}

export async function restartCrawling(body) {
  const { projectId, initUrl } = body;
  return axios
    .post("project/crawl", { projectId, initUrl })
    .then((res) => res.data)
    .catch((err) => {
      return { success: false };
    });
}

export async function fetchTmCountByQuery(params) {
  return axios
    .post("translation/tm/count", params)
    .then((res) => res.data)
    .catch((err) => {
      return { success: false };
    });
}

export async function fetchAllDomains(params) {
  return axios
    .post("domain/fetchAllDomains", params)
    .then((res) => res.data)
    .catch((err) => {
      return { success: false };
    });
}

export async function fetchDomainWiseUrl(params) {
  return axios
    .post("scope/domainWiseUrls", params)
    .then((res) => res.data)
    .catch((err) => {
      return { success: false };
    });
}
export async function analysisWordCount(projectId) {
  return axios
    .get(`project/wordcount/${projectId}`)
    .then((res) => res.data)
    .catch((err) => {
      return { success: false };
    });
}

export async function projectAccessVerification(params) {
  return axios
    .post("project/access/verify", params)
    .then((res) => res.data)
    .catch((err) => {
      return { success: false };
    });
}

export async function apTranslationProject(params) {
  return axios
    .post("project/apiTranslation/create", params)
    .then((res) => res.data)
    .catch((err) => {
      return { success: false };
    });
}

export async function deleteApiTranslationProject(params) {
  return axios
    .post("project/apiTranslation/delete", params)
    .then((res) => res.data)
    .catch((err) => {
      return { success: false };
    });
}

// post glossary transliteration data
export async function setGlossaryTransliterationData(formData) {
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  return axios
    .post("transliterationGlossary/create", formData, config)
    .then((res) => res.data);
}

// fetch glossary transliteration data
export async function getGlossaryTransliterationData(params) {
  return axios
    .post("transliterationGlossary/get", params)
    .then((res) => res.data);
}

// delete glossary transliteration data
export async function deleteGlossaryTm(params) {
  return axios
    .post("transliterationGlossary/delete", params)
    .then((res) => res.data);
}

export async function fectchGlossaryList(params) {
  return axios
    .post("transliterationGlossary/csv/get", params)
    .then((res) => res.data);
}

export async function addPopUpCssToSnippet(params) {
  return axios.post("snippet/pop-up/css/update", params).then((res) => res.data);
}

export async function fetchPopUpCssDetails(params) {
  return axios.post("snippet/pop-up/css/fetch", params).then((res) => res.data);
}
